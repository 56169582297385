import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import Loader from '../../../components/Loader';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';

const PartnerEnablementProgramsPage = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Partner Enablement Programs - Expert Insights" />
            <div className="ei-partner-enablement-program-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <p className="hero-subtext white-text">
                                Expert Insight
                            </p>
                            <h1 className="white-text">
                                How to Structure Your Partner Enablement
                                Programs
                            </h1>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text max-width-900">
                            <WistiaPopoverVideo id={'qti0hz7sdo'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/expert-insights/partner-enablement-programs/thumbnail.png')}
                                    alt="Partner Enablement tips and tricks video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="one-half download-checklist-column">
                            <a
                                href="https://05ab5854d232fd37cd92-d8310f79ece792c4405f169f3d25ed4a.ssl.cf1.rackcdn.com/Marketing/partner-enablement-program-efficacy-checklist.pdf"
                                target="_blank"
                            >
                                <img
                                    src={require('../../../images/expert-insights/partner-enablement-programs/checklists.png')}
                                    alt="Partner enablement checklists"
                                />
                            </a>
                            <a
                                href="https://05ab5854d232fd37cd92-d8310f79ece792c4405f169f3d25ed4a.ssl.cf1.rackcdn.com/Marketing/partner-enablement-program-efficacy-checklist.pdf"
                                className="btn blue"
                                target="_blank"
                            >
                                <span className="btn__mask"></span>
                                <span className="btn__text">
                                    Download Checklist
                                </span>
                            </a>
                        </Col>
                        <Col className="one-half">
                            <p>
                                Getting ROI from your partner enablement
                                programs and MDF is a constant battle of
                                attrition. You need to have the right tools and
                                strategies in place to ensure continuous
                                performance in a marketing environment that is
                                always changing.
                            </p>
                            <p>
                                In this video, Giovanni Sanguily, Founder and
                                CEO of TRIdigital Marketing and GlassHive,
                                shares some valuable insights on how you should
                                think about your partner programs and the types
                                of tools you need at your disposal.
                            </p>
                            <p>
                                If you want to assess your past or present
                                partner enablement programs, download our free
                                checklist! It’s a great place to start.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row className="flexed-mobile stretch">
                        <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                            <form
                                id="vendors-form"
                                className="pink-form"
                                method="POST"
                                name="Vendors Form"
                                action="/expert-insights/partner-enablement-program/#thanks"
                                encType="multipart/form-data"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                data-category="Vendor"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'sbmt-form-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const formName = document.getElementById(
                                        'vendors-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(formName.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'vendors-form'
                                            )
                                        ),
                                    }).then(res => {
                                        if (res.status === 200) {
                                            document.querySelector(
                                                '#vendors-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'block';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="vendors-form"
                                />
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <h3 className="white-text form-title">
                                    Want some one-on-one time to talk partner
                                    enablement? Fill out the form to speak to a
                                    channel expert today!
                                </h3>
                                <div className="what-do-we-call-you">
                                    <div className="field name-field">
                                        <label htmlFor="firstName" hidden>
                                            What’s your name?
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder="Name"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field email-field">
                                        <label hidden htmlFor="email">
                                            What’s your email address?
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field">
                                        <label
                                            className="second-label"
                                            hidden
                                            htmlFor="company"
                                        >
                                            What company are you with?
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                            id="company"
                                        ></input>
                                    </div>
                                </div>

                                <div className="field comment-field">
                                    <label
                                        hidden
                                        className="textarea-label"
                                        htmlFor="message"
                                    >
                                        What question can we answer for you?
                                    </label>
                                    <textarea
                                        type="text"
                                        name="message"
                                        id="message"
                                        placeholder="Comments"
                                    ></textarea>
                                </div>
                                <div className="hide-me field">
                                    <input
                                        data-form-type="Consultation"
                                        className="formcat"
                                    ></input>
                                </div>
                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg">
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        id="sbmt-form-btn"
                                        type="submit"
                                        className="pink-button"
                                    >
                                        Get In Contact
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for contacting us. We'll be in
                                    touch shortly!
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default PartnerEnablementProgramsPage;
